//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logo from '@assets/logo.png';
import mobileYzm from '/src/views/login/components/mobile-yzm'
import register from '/src/views/login/register'
import forgetPwd from '/src/views/login/forgetPwd'
import updatePwd from '/src/views/login/update-pwd'


export default {
    components:{mobileYzm,register,forgetPwd,updatePwd},
    data: () => ({
        form: {
            logintype:'',
            // loginname: '',
            // pwd: '',
        },

        logoSrc: logo,
        activeName:'pwd',
        isPwdLogin:true,
        isRegister:false,
        isForgetPwd:false,
        isUpadtePwd:false,

        autoLogin:false,
        isOnline:false,

    }),
    watch:{
        activeName:{
            handler:function(n,o){
                this.form = {};
                if(n == "phone"){
                    this.isPwdLogin = false;
                    this.form.logintype = '2';
                }else{
                    this.isPwdLogin = true;
                    this.form.logintype = '1';
                }
            },
            immediate:true
        },

        '$route.query.loginform': {
            handler(n,o){
                if(window.location.host == 'partner.bhtong.cn'){
                    if(!!n && n == 1){
                        this.isOnline = false;
                    }else{
                        this.isOnline = true;
                        this.form.logintype = '2';
                    }
                }
            },
            deep: true,
        },
        
    },
    methods: {
        /*登录函数*/
        login: function () {
            let that = this
            that.$refs['form'].validate(function (valid) {
                if(valid){
                    if(that.autoLogin == true){
                        that.form.autoLogin = that.autoLogin;
                    }
                    that.$store.dispatch('login', that.form);
                }
            })
        },
        //注册账户
        goRegisteredAcc:function(){
            this.isRegister = true;
        },
        getAccountLogin:function(){
            this.isRegister = false;
            this.activeName = 'pwd';
        },

        //忘记密码
        goForgetPwd:function(){
            this.isForgetPwd = true;
        },
        getGoLogin:function(){
            this.isForgetPwd = false;
            this.activeName = 'pwd';
        },

        //更换手机号
        goUpdatePwd:function(){
            this.isUpadtePwd = true;
        },
        getUpadteLogin:function(){
            this.isUpadtePwd = false;
            this.activeName = 'pwd';
        }

    },
    mounted() {
        if(this.$store.getters.userToken.token){
            let nowtime = new Date();
            if(nowtime.getTime() < this.$store.getters.userToken.expirein){
                this.$store.dispatch('isAutoLogin');
            }
        }
    },
    created(){
        //判断线上
        if(window.location.host == 'partner.bhtong.cn'){
            if(!!this.getQuery('loginform') && this.getQuery('loginform') == 1){
                this.isOnline = false;
            }else{
                this.isOnline = true;
                this.form.logintype = '2';
            }
        }else{
            this.isOnline = false;
        }
    }
}
