//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: Object,
            default: function() {
                return {
                    mobile: '',
                    code: ''
                }
            }
        },
        label:{
            type:String,
            default:'',
        },
        resurl:{
            type:String,
            default:'sendLoginCode', 
        },
        isLogin:{
            type:Boolean,
            default:false,
        }
    },
    data () {
        var checkPhone = function (rule, value, callback) {
            if (!(/^1\d{10}$/.test(value))) {
                callback(new Error('手机号格式错误！'));
            } else {
                callback()
            }
        }
        return {
            disabled: false,
            text: '获取验证码',

            mobileRules: [{required: true, message: '手机号不能为空', trigger: 'blur'},{ validator: checkPhone, trigger: 'blur'}]
        };
    },
    computed: {
        model: {
            get: function() {
                return this.value;
            },
            set: function(n, o) {
                this.$emit('input', n);
            }
        },
    },
    watch: {
        
    },
    methods: {
        sendcode: function () {
            if (!!this.model.mobile && /^1\d{10}$/.test(this.model.mobile)) {
                new Chain().link(function (that, next) {
                    if(that.isLogin == true){
                        that.$post('/bhtong/user/checkLogin',{
                            logintype: "2",
                            mobile: that.model.mobile,
                        },function(data){
                            next();
                        },true)
                    }else{
                        next();
                    }
                }).link(function(that, next) {
                    that.$get(`/bhtong/notify/${that.resurl}`, {
                        mobile: that.model.mobile
                    }, function (data) {
                        ShowMsg('验证码已发送', 'success');
                        that.settime();
                        next();
                    })
                }).run(this);
            } else {
                ShowMsg('请先输入正确的手机号！', 'warning');
            }

        },
        settime: function () {
            var that = this;
            this.text = 60;
            this.disabled = true;
            var interval = setInterval(function () {
                that.text--;
                if (that.text == 0) {
                    that.disabled = false;
                    that.text = '发送验证码'
                    clearInterval(interval);
                }
            }, 1000)
        },
    },
    created () { },
    mounted () { },
};
