import { render, staticRenderFns } from "./mobile-yzm.vue?vue&type=template&id=72ccdc1a&scoped=true&"
import script from "./mobile-yzm.vue?vue&type=script&lang=js&"
export * from "./mobile-yzm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ccdc1a",
  null
  
)

export default component.exports