//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import mobileYzm from '/src/views/login/components/mobile-yzm'

export default {
    components: {mobileYzm},
    props:{},
    data: () => ({
        agreement:false,
        registerForm: {
            khsshy:'other',
        },
        sshyList:[
            {diccode: 'agent' , dicname:'招标代理'},
            {diccode: 'college' , dicname:'高校'},
            {diccode: 'energy' , dicname:'能源'},
            {diccode: 'municipal' , dicname:'市政'},
            {diccode: 'consult' , dicname:'咨询公司'},
            {diccode: 'hospital' , dicname:'医院'},
            {diccode: 'finance' , dicname:'金融'},
            {diccode: 'other' , dicname:'其他'},
        ],
        time: null,
        msgError:'',    // 接收校验的错误信息
    }),
    methods: {
        accountLogin:function(){
            this.$emit('accountLogin')
        },
        registerHandler:function(){
            let that = this 
            // 校验不通过，提示错误信息
            if(that.msgError){
                return ShowMsgBox(that.msgError, 'error');
            }
            if(that.agreement == false){
               return ShowMsgBox('请<font style="color:#f18241;">阅读并同意服务协议</font>后进行后续操作', 'error');
            }

            that.$refs['registerForm'].validate(function (valid) {
                if(valid){
                    that.$post(that.$store.getters.userUrl + '/register',that.registerForm,function(data){
                        ShowMsg('注册成功','success');
                        that.$emit('accountLogin');
                    },true)
                }
            })
        },
        checkPwd: function (rule, value, callback) {
            if (value !== this.registerForm.pwd) {
                callback(new Error('输入密码不一致'));
            } else {
                callback()
            }
        },

        // 防抖 校验
        fdCheck(){
            this.FD(this.registerCheck);   
        },

        registerCheck:function(){
            let that = this;
            that.$ajax({
                type: 'post',
                url: that.$store.getters.userUrl + '/registerCheck',
                data: that.registerForm,
                callback: function() {

                },
                complete: function(res) {
                    if(res.code != 'v'){
                        ShowMsg(res.msg,'error');
                        return
                    }

                    if(res.tdata.errorMsg){
                        ShowMsg(res.tdata.errorMsg,'error');
                        that.msgError = res.tdata.errorMsg
                        return
                    } else{
                        that.msgError = '';
                    }


                    // if(res.code != 'v'){
                    //     if(res.msg.includes('用户名')){
                    //         that.$set(that.registerForm , 'loginname' , '');

                    //     } else if (res.msg.includes('手机号')){
                    //         that.$set(that.registerForm , 'mobile' , '');

                    //     } else if (res.msg.includes('介绍人')){
                    //         that.$set(that.registerForm , 'refereeusername' , '');
 
                    //     }  
                    //     if (res.msg.includes('介绍人手机号')){
                    //         that.$set(that.registerForm , 'refereemobile' , '');
                    //     } 
                    // }

                },
                fztype:true
            });
            
        },

        FD(cd) {
			if (this.time != null) {
				clearTimeout(this.time);
			}

			this.time = setTimeout(() => {
                cd && cd();
			}, 200)
		},           
					
    },
    mounted () {

    }
}
