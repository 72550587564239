//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import mobileYzm from '/src/views/login/components/mobile-yzm'

export default {
    components: {mobileYzm},
    props:{

    },
    data: () => ({
        forgetPwdForm:{},
    }),
    methods: {
        forgetPwdHandler:function(){
            let that = this 
            that.$refs['forgetPwdForm'].validate(function (valid) {
                if(valid){
                    that.$post(that.$store.getters.userUrl + '/forget',that.forgetPwdForm,function(data){
                        ShowMsg('修改成功','success');
                        that.$emit('setGoLogin');
                    },true)
                }
            })
        },
        goLogin:function(){
            this.$emit('setGoLogin');
        }
    },
    mounted () {

    }
}
